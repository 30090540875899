@media screen and (max-width: 992px) {
    .Favourite_Sector_section {
        padding: 20px 0px;
    }

    .menu_toggle_btn {
        z-index: 99999;
    }

    .transaction_filter_div .account_filter_btn {
        font-size: 14px;
        margin-bottom: 10px;
    }

    .account_filter_div {
        flex-direction: column;
        align-items: flex-start;
    }

    .transaction_filter_div div {
        margin-top: 10px;
    }

    .personal_detail_flex {
        flex-direction: column;
    }

    .personal_detail_flex h3 {
        margin-bottom: 10px
    }

    .profile_img img {
        max-width: 18px;
    }

    .profile_card {
        padding: 10px 4px;
    }

    .profile_status h3 {
        font-size: 1rem;
    }

    .yellow_btn,
    .light_btn {
        font-size: 0.8rem;
        padding: 4px;
        margin-top: 20px !important;
    }

    .login_main_section .yellow_btn,
    .light_btn {
        font-size: 1rem;
        padding: 10px 16px;
        margin-top: 20px !important;
    }

    .register_link {
        flex-direction: column;
        text-align: center;
    }

    .profile_card .d_flex_both_center_sp_bt {
        flex-direction: column;
        align-items: flex-start;
    }

    .profile_img {
        min-width: 30px;
        min-height: 30px;
        width: 30px;
        height: 30px;
    }

    .hero_setion {
        padding-top: 60px;
    }

    .menu_toggle_btn {
        top: 23px;
        left: 50%;
        transform: translate(-50%, 0);
    }

    .main_header .logo {
        /* margin-left: 70px; */
    }

    .navbar-toggler {
        background-color: #ff5722;
    }

    .dashboard_header .navbar-nav {
        align-items: flex-start;
    }

    .header_Profile {
        border-left: 0;
        margin-left: 0px
    }
}

@media screen and (max-width: 767px) {
    .side_content_main_box {
        padding: 10px;
    }

    .form_box {
        padding: 20px 15px;
    }

    .hero_section_img img {
        max-width: 300px;
        margin-top: 20px;
    }

    .Incredible_Features_section .headings {
        margin-top: 30px;
    }

    .headings h1,
    .headings h2 {
        font-size: 2rem;
    }

    .search_box input {
        width: 100%;
    }

    .start_now_btn_div {
        margin-top: 30px;
    }

    .features_section {
        padding: 50px 0;
    }

    .account_main_div {
        padding: 10px;
        flex-direction: column;
        align-items: flex-start;
    }

    .sidebar_link_icon {
        font-size: 18px;
        padding-right: 2px;
    }

    .account_box_side_buttons a {
        padding: 4px;
    }

    .side_content_main_box .headings h2 {
        font-size: 20px;
        margin-bottom: 20px;
    }

    .account_filter_btn {
        padding: 4px 8px;
        font-size: 14px;
    }

    .account_type_details ul li {
        font-size: 0.6rem;
    }

    .account_box_side_buttons {
        display: flex;
        flex-direction: column;
        width: 100%;
        text-align: center;
    }

    .account_box_side_buttons a {
        width: 100%;
        margin-bottom: 10px;
    }

    .side_content_main_box .headings {
        flex-direction: column;
        align-items: flex-start;
    }

    .login_main_section {
        padding: 40px 20px;
    }

    .form_box h2 {
        font-size: 1.5rem;
    }

    .headings h2 {
        font-size: 1rem;
    }

    .account_box_side_buttons button {
        margin-right: 0;
    }

    .dashboard_boxes .dashboard_boxes_left_side:nth-child(2) {
        border-left: 0px solid #eee;
        padding-left: 0px;
    }

    .dashboard_boxes {
        padding: 10px;
    }

    .side_content_main_box {
        padding: 0px;
    }

    .SideContent_div {
        box-shadow: none;
        overflow: hidden;
    }



}

@media screen and (max-width: 500px) {

    .input-container input,
    .input-container select,
    .validaty_box {
        min-width: auto !important;
        max-width: auto !important;
    }

    .validaty_box {
        padding: 5px;
    }

    .flex_radio_buttons_div {
        /* flex-direction: column; */
        align-items: flex-start;
    }

    .input-container {
        margin: 1px;
    }

    .modal-body {
        padding: 0.5rem;
    }

    .dashboard_header .main_header .logo {
        max-width: 50px;
        margin-right: 0px;
        /* margin-left: 60px; */
    }

    .dashboard_boxes_flex {
        flex-direction: column;
        align-items: flex-start;
    }



}