.login_main_section {
    padding: 100px;
}

.login_main_section .nav-tabs {
    margin-bottom: 50px !important;
}

.form_box {
    background-color: #f7f7f7;
    padding: 30px;
    border-radius: 8px;
}

.form_box h2 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    margin-top: 20px;
}

form label {
    font-size: 1rem;
    font-family: var(--detail_font);
}

form input,
form select {
    font-size: 1.2rem;
}

form input:focus,
form select:focus {
    box-shadow: none !important;
    border: 1px solid #444 !important;
    background-color: transparent;
}

.register_link {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
}

.register_link p {
    margin-bottom: 0;
}

.register_link a {
    color: var(--text_dark);
    font-weight: 600;
}

form .terms_msg_label {
    font-size: 1rem;
    font-family: var(--commaon_font);
}