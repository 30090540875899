* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

a {
    text-decoration: none;
}

:root {
    --commaon_font: 'Urbanist', sans-serif;
    --detail_font: 'Poppins', sans-serif;
    --bg_yellow: #ff5722;
    --bg_light: #dadada;
    --text_dark: #0a2745;
    --text_light: #fff;
    --text_yellow: #ff5722;
}

body {
    font-family: var(--commaon_font);
    letter-spacing: 1px;
}

.yellow_btn {
    background-color: var(--bg_yellow);
    color: var(--text_light);
    border: none;
    outline: none;
    padding: 6px 16px;
    border-radius: 4px;
    border: 1px solid var(--text_light);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.5px;
}

.light_btn {
    background-color: transparent;
    color: #000;
    border: none;
    outline: none;
    padding: 6px 16px;
    border-radius: 4px;
    border: 1px solid #000;
    margin-right: 12px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.5px;
    transition: 0.3s all ease-in-out;
}

.light_btn:hover {
    background-color: var(--bg_yellow);
    color: #fff;
    border: 1px solid var(--text_dark);
}

.yellow_btn:hover {
    background-color: var(--bg_light);
    color: var(--bg_yellow);
    border: 1px solid var(--text_dark);
}

.headings h1 {
    font-size: 3rem;
    font-weight: 700;
    color: var(--text_dark);
}

.headings h2 {
    font-size: 1.3rem;
    font-weight: 600;
    color: var(--text_dark);
    font-family: var(--commaon_font);
}

.headings span {
    color: var(--text_yellow);
}

.common_para {
    font-size: 1rem;
    font-family: var(--detail_font);
    font-weight: 300;
}

.mb_50 {
    margin-bottom: 50px;
}

/* ============Header================== */

.main_header {
    background-color: #fff;
}

.nav-link {
    font-weight: 400;
    font-size: 14px;
    color: #000;
    text-transform: capitalize;
    font-family: var(--detail_font);
}

.main_header .logo {
    max-width: 60px;
    margin-right: 50px;
}

/* ============hero section================== */

.blue_grident {
    /* background: linear-gradient(1turn, #138c61 24.59%, #4abf96 143.75%) !important; */
    /* background-image: radial-gradient(circle 610px at 5.2% 51.6%, #050872 0%, #070335 97.5%); */
    background-color: #f7f7f7;
}

.hero_setion {
    padding-top: 140px;
}





.search_box {
    margin-top: 20px;
    position: relative;

}

.search_box input {
    width: 85%;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 0.5px;
    color: var(--text_dark);
    position: relative;
    padding-left: 36px;
}

.search_box .icon {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translate(0%, -50%);
    font-size: 20px;
    color: #191919;
}

.search_box input:focus {
    box-shadow: none;
    border: 1px solid #333;
}

.hero_filter_btns {
    margin-top: 20px;
}

.hero_filter_btns button {
    background-color: transparent;
    padding: 4px 8px;
    border-radius: 8px;
    color: var(--text_dark);
    font-size: 0.8rem;
    font-weight: 600;
    box-shadow: none;
    outline: none;
    border: 1px solid var(--text_dark);
    letter-spacing: 0.5px;
    margin-right: 10px;
    font-family: var(--detail_font);
    margin-bottom: 12px;
}

.start_now_btn_div {
    margin-top: 60px;
}

.start_now_btn_div p {
    font-size: 1rem;
    font-family: var(--detail_font);

}

.start_now_btn {
    padding: 10px 16px;
    border: none;
    font-family: var(--detail_font);
}

.hero_section_img {
    display: flex;
    align-items: center;
    justify-content: center;
}

.hero_section_img img {
    max-width: 400px;
}



/* ============Features============== */

.features_section {
    padding: 100px 0;
}

.features_section h2 {
    text-align: center;
}

.features_box_main {
    border: 1px solid #b2b2b2;
    padding: 40px;
    border-radius: 8px;
    background-color: #f7f7f7;
    margin-bottom: 20px;
}

.features_box_main img {
    margin-bottom: 20px;
    max-width: 48px;
    width: 48px;
}

.features_box_main h3 {

    font-family: var(--detail_font);
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-weight: 600;
    color: var(--text_yellow);
}

.features_box_main p {
    font-family: var(--commaon_font);
    font-size: 1rem;
    color: #464646;
    font-weight: 500;
}

.features_box_main_bg_one {
    background-color: #38a982;
}

.features_box_main_bg_one h3 {
    color: #fff;
}

.features_box_main_bg_one p {
    color: #fff;
}

/* ============Most Popular Stocks============== */
.Most_Popular_Stocks_section {
    background-color: #f7f7f7;

}

.Most_Popular_Stocks_tabs {
    margin-bottom: 50px;
}

.Most_Popular_Stocks_tabs .nav-link {
    color: var(--text_dark);
    font-size: 1rem;
    font-weight: 500;
    font-family: var(--detail_font);
    background: #38a982;
    color: #fff;
    margin: 0 8px;
    border-radius: 12px;
    border: none;
    margin-bottom: 10px;
}

.Most_Popular_Stocks_tabs .nav-tabs .nav-link.active {
    color: var(--text_light);
    background-color: var(--bg_yellow);
    border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: none;
}


.stocks_box_main {
    border: 1px solid #b2b2b2;
    padding: 20px 20px;
    border-radius: 8px;
    background-color: #f7f7f7;
    margin-bottom: 20px;
}

.stocks_box_main img {
    max-width: 48px;
    margin-bottom: 20px;
}

.stocks_box_main h3 {
    color: #363636;
    font-size: 1.2rem;
    font-weight: 600;
    font-family: var(--commaon_font);
}

.stocks_box_main h4 {
    color: var(--text_dark);
    font-size: 1rem;
    font-weight: 500;
    font-family: var(--commaon_font);
}

.stock_red_indicator {
    color: #E07575;
}

.stocks_box_main h4 img {
    margin: 0;
}

.stock_green_indicator {
    color: #5CA81D;
}


/* =============Favourite_Sector_section============== */
.Favourite_Sector_section {
    padding: 60px;
    background-color: #21195f;
}

.Favourite_Sector_box {
    border: 1px solid #7d7d7d;
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 12px 16px;
    transition: 0.3s all ease-in-out;
    margin-bottom: 20px;
}

.Favourite_Sector_box img {
    max-width: 24px;
    margin-right: 12px;
}

.Favourite_Sector_box h4 {
    margin: 0;
    font-size: 1rem;
    color: var(--text_light);
    font-weight: 400;
    font-family: var(--commaon_font);
}

.Favourite_Sector_box:hover {
    background-color: #dadada0d;
}

/* ============Incredible_Features_section============= */

.Incredible_Features_section {
    padding: 60px 0;
}

.Incredible_Features_section p {}

.Incredible_Features_small_box {
    box-shadow: 0 2px 10px 0 rgba(239, 147, 9, .2);
    border: 1px solid #f9d49d;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    margin-top: 20px;
}

.Incredible_Features_small_box img {
    max-width: 36px;
}

.Incredible_Features_small_box h4 {
    font-size: .875rem;
    line-height: 1.25rem;
    margin-top: 10px;
    font-weight: 500;
    font-family: var(--detail_font);
}

/* ===============Standard_Prices_section================= */
.Standard_Prices_section {}

.Standard_Prices_section .pricing_box {
    border-radius: 20px;
    background: linear-gradient(137deg, #273675, #26346f 19.91%, #222d56);
    box-shadow: inset 0 1.0027413368225098px 1.3369884490966797px 0 rgba(143, 167, 252, .1);
    padding: 20px;
    position: relative;
    overflow: hidden;
    min-height: 260px;
}

.Standard_Prices_section .pricing_box h2 {
    color: var(--bg_light);
    font-size: 1rem;
    text-align: left !important;
    margin-top: 100px;
}

.Standard_Prices_section .pricing_box .common_para {
    color: var(--bg_light);
    font-size: .875rem;
    line-height: 1.25rem;
    color: #919BC6;

}


.pricing_bg_round {
    width: 160px;
    height: 160px;
    background-color: #38a982;
    border-radius: 50%;
    position: absolute;
    right: -40px;
    top: -60px;
}

.pricing_bg_round_main h1 {
    color: var(--text_light);
    position: absolute;
    right: 20px;
    top: 20px;
    font-family: var(--commaon_font);
    font-size: 2rem;
}

.pricing_bg_round_main h1 span {
    font-size: 2.5rem;
    margin-left: 6px;
    font-family: var(--detail_font);
}

/* ============FAQ Section=============== */

.accordion-item {
    border: none !important;
    margin-bottom: 20px;

}

.accordion-header button {
    background-color: #f7f7f7 !important;
    border: none !important;
    border-radius: 30px !important;
    font-size: 1.2rem;
    color: var(--text_dark);
    font-weight: 500;
    border: 1px solid #f7f7f7 !important;

}

.accordion-header button:focus {
    box-shadow: none;
    border: 1px solid var(--bg_yellow) !important;
    color: var(--bg_yellow);
}

.accordion-body {
    color: #363636;
    font-size: 1rem;
}

.accordion-item {
    background: #f7f7f7;
    border-radius: 20px !important;
}


/* ==============Footer=================== */
.footer_main_section {
    background-color: #0e0e0e;
    padding: 40px 0 20px 0;
}

.footer_main_section .logo {
    margin-bottom: 30px;
}

.footer_box_main h4 {
    color: var(--text_light);
    font-size: 0.9rem;
}

.footer_box_main {
    margin-bottom: 30px;
}

.footer_box_main h3 {
    color: var(--text_light);
    font-size: 1.2rem;
    font-family: var(--detail_font);
    font-weight: 400;
}

.footer_box_main a {
    color: #a1a1a1;
    font-size: .9rem;
    font-family: var(--commaon_font);
    font-weight: 400;
    margin-bottom: 20px !important;
    transition: 0.3s all ease-in-out;
}

.footer_box_main .social_icons_div a:hover {
    background-color: var(--bg_yellow);
}

.social_icons_div {
    display: flex;
}

.social_icons_div a {
    background-color: #fff;
    border-radius: 50%;
    min-width: 30px;
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.social_icon {
    color: #0a2745;
}

.footer_copyright_div {
    border-top: 0.1px solid #3c3c3c;
    text-align: center;
    padding-top: 20px;
}

.footer_copyright_div p {
    color: #8f8f8f;
    margin-bottom: 0;
    font-size: 0.9rem;
}

.footer_main_section .logo {
    width: 160px;
}

#tradingview_1b982-wrapper {
    height: 500px !important;
}