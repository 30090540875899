.dashboard_header {
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.1);
    position: sticky;
    top: 0;
    z-index: 9999;
}

.navbar-toggler-icon {
    background-image: url("../../../public/hamburger.png");
}

.dashboard_header .nav-link {
    color: #000;
    font-size: 12px;
    margin-left: 20px;
    font-weight: 400;
}

.dashboard_header .nav-item .active {
    color: #ff5722;
    font-weight: 500;
}

.navbar-toggler:focus {
    outline: none;
    box-shadow: none;
    border: none;
}

.dashboard_header .navbar-nav {
    align-items: center;
}

.navbar-dark .navbar-toggler-icon::after {
    content: "\f0c9";
    font-family: "FontAwesome";
    font-size: 22px;
    color: #333;
}

.header_Profile {
    border-left: 1px solid #cdcdcd;
    margin-left: 20px;
}

.user_profile_image {
    max-width: 30px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    margin-right: 10px;
}

.side_content_main_box {
    padding: 20px 20px 20px 20px;
}

.side_content_main_box .headings {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
}

.side_content_main_box .headings a,
.side_content_main_box .headings button {
    border: 1px solid #d9d9d9;
    padding: 4px 8px;
    font-size: 0.8rem;
    font-weight: 600;
    border-radius: 4px;
    color: #000;
    transition: 0.3s all ease-in-out;
    background-color: #fff;
}

.side_content_main_box .headings a:hover,
.side_content_main_box .headings button:hover {
    background-color: #ff5722;
    border: 1px solid #ff5722;
    color: #fff;
}

.main_section {
    position: relative;
    background-color: #fff;
}

.main__section {
    width: 100%;
    display: flex;
    width: 100%;
    transition: 0.3s all ease-in;
    /* margin: 20px 0; */
    padding: 20px 0;

}

.menu_toggle_btn {
    position: fixed;
    top: 9px;
    left: 20px;
    display: none;
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 2px;
    /* background: #ff5722; */
    background-color: transparent;
    display: flex !important;
    align-items: center;
    justify-content: center;
    z-index: -99;
}

.menu_toggle_btn h6 {
    font-size: 16px;
    font-family: var(--detail_font);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: var(--bg_yellow);
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.menu_toggle_btn h6 .icons {
    margin-right: 8px;
}

.menu_toggle_btn .icon {
    font-size: 24px;
    color: #fff;
}

.menu_close_btn {
    position: absolute;
    z-index: 999;
    top: 9px;
    left: 260px;
    display: none;
    width: 40px;
    height: 40px;
    border: none;
    background-color: transparent;
    transition: 0.3s all ease-in;
}

.menu_close_btn .icon {
    font-size: 24px;
    color: #fff;
    transition: 0.3s all ease-in;
}

.sidebar_div {
    min-width: 25%;
    transition: 0.3s all ease-in;
    min-height: 100vh;
    background: #fff;
    height: auto;
    margin-right: 10px;
}

.SideContent_div {
    width: 100%;
    transition: 0.3s all ease-in;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    overflow: auto;
}

.sidebar_div_coll {
    width: 0;
    transition: 0.3s all ease-in;
    height: 100%;
}

.SideContent_div_coll {
    min-width: 100%;
    transition: 0.3s all ease-in;
}

/* ====================================== */
/* ============sidebar=============== */
/* ====================================== */

.dashboard_sidebar_main_div {
    background: #fff;
    /* width: 320px; */
    /* min-height: 100vh; */
    height: 100%;
    height: auto;
    position: relative;
    z-index: 999;
}

.white_logo_div {
    text-align: center;
    /* padding: 20px 0 20px 0; */
    height: 57px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;
}

.white_logo {
    width: 80px;
}

.sidebar_link {
    padding: 8px;
    color: #000;
    font-size: 0.7rem;
    font-family: var(--detail_font);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 95%;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f0f0f0;
    border-radius: 4px;
}

.sidebar_link_icon {
    padding-right: 4px;
    /* width: 48px; */
    font-size: 25px;
    font-weight: 500;
}

.sidebar_link:hover {
    text-decoration: none;
    color: #fff;
}

.active_link {
    border-radius: 0px 5px 5px 0px;
    background: #0848af;
    box-shadow: 0px 12px 20px 0px #0a3882;
}

.signout_btn_div {
    text-align: center;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    width: 100%;
}

.signout_btn_div a {
    color: #fff;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background-color: transparent !important;
    box-shadow: none !important;
    letter-spacing: 1px;
    /* padding: 12px; */
    height: 52px;
}

.dashboard_sidebar_main_div .active,
.dashboard_sidebar_main_div .sidebar_link:hover {
    border-radius: 4px;
    background: #fff;
    font-weight: 500;
    /* color: #ff5722; */
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

/* ====================================== */
/* ============Header=============== */
/* ====================================== */

.header_main_div {
    /* height: 80px; */
    min-width: 100%;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 28px;
    border-bottom: 1px solid #6e6e6e;
}

.header_search_box {
    display: flex;
    align-items: center;
}


.header_search_box input {
    color: #6e6e6e;
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    width: 100%;
    margin-left: 10px;
    border: 1px solid transparent;
    outline: none;
    padding: 2px 10px;
    border-radius: 5px;
    min-width: 300px;
    letter-spacing: 1px;
}

.header_search_box input:focus {
    border: 1px solid #ff5722;
}

.header_search_box input::placeholder {
    color: #6e6e6e;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
}

.header_right_side_div {
    background: #000;
    padding: 16px 0 16px 16px;
    /* height: 80px; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.border_right {
    border-right: 1px solid #fff;
    /* height: 41px; */
    /* width: 41px; */
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 14px;
    padding-left: 14px;
}

.border_right a img {
    min-width: 28px;
}

.header_profile_btn {
    margin-left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.header_profile_btn .icon,
.header_profile_btn img {
    /* border: 1px solid #fff; */
    padding: 3px;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    color: #fff;
    font-weight: 200;
}

.header_profile_btn a {
    margin-bottom: 0;
    padding-bottom: 0;
}

.header_profile_btn a span {
    color: var(--bg_light);
    font-family: var(--commaon_font);
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    padding-left: 10px;
    margin-bottom: 0;
    padding-bottom: 0;
    letter-spacing: 1px;
    margin-right: 28px;
}

.header_balance a span {
    font-size: 1.5rem;
    font-weight: 500;
    font-family: var(--detail_font);
    margin-right: 0;
    color: var(--text_yellow);
}

.mt_12 {
    margin-top: 12px;
}


.dashbox_heading_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.eye_button {
    border: none;
    outline: none;
    padding: 5px;
    margin: 0;
    border-radius: 10px;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    margin-top: -15px;
    background-color: whitesmoke;
}

.eye_button .icon {
    font-size: 20px;
    color: #000;
}

/* dashboard_toggle */
@media screen and (max-width: 992px) {
    .menu_toggle_btn {
        display: block;
    }

    .SideContent_div_coll .menu_close_btn {
        display: block;
        transition: 0.3s all ease-in;
    }

    .sidebar_div_coll {
        width: 100%;
        position: absolute;
        left: 0;
    }

    .sidebar_div {
        position: absolute;
        left: -560px;
    }

    .header_main_div {
        padding-left: 80px;
        position: sticky;
        top: 0;
        z-index: 9;
    }
}

@media screen and (max-width: 768px) {
    /* .main_header .logo {
        margin-right: 80px;
        margin-left: 50px;
    } */
}

.side_content_main_box .headings {
    border-bottom: 1px solid #cdcdcd;
    margin-bottom: 20px;
}

.side_content_main_box .headings h2 {
    color: #000;
    font-family: var(--detail_font);
    font-weight: 400;
    font-size: 1.2rem;
}

.dashboard_header .icons {
    font-size: 24px;
}

.dashboard_header .profile_icon {
    font-size: 19px;
}

/* ============my accounts page============= */
.real_demo_tabs .nav-tabs {
    justify-content: flex-start;
    background-color: #f0f0f0;
    border-radius: 4px;
}

.real_demo_tabs .Most_Popular_Stocks_tabs {
    margin-bottom: 10px;
}

.real_demo_tabs .Most_Popular_Stocks_tabs .nav-link {
    margin-bottom: 0;
    background-color: transparent;
    color: var(--text_dark);
    margin: 0;
    border-radius: 4px;
    font-size: 0.7rem;
    font-family: var(--commaon_font);
    font-weight: 600;
    padding: 10px;
    letter-spacing: 1px;
}

.account_filter_div {
    display: flex;
    justify-content: space-between;
}

.account_filter_btn {
    background-color: #ff5722;
    border: none;
    padding: 6px 20px;
}

.account_filter_btn:focus {
    box-shadow: none;
}

.account_main_div {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    border-radius: 4px;
    border: 1px solid #ddd;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.account_type_details ul {
    display: flex;
    margin: 0;
    padding: 0;
}

.account_type_details ul li {
    background-color: #c6c6c6;
    border-radius: 10px;
    font-size: 0.8rem;
    padding: 3px 10px;
    margin-right: 5px;
    margin-bottom: 0;
    padding-bottom: 0;
    font-weight: 500;
}

.account_type_details ul li:first-child {
    background-color: #ff5722;
    color: #fff;
}

.demo_account_type ul li:first-child {
    background-color: #ff5722;
}

.account_type_details ul li:last-child {
    background-color: transparent;
}

.account_main_div .amount h2 {
    font-size: 1.5rem;
    font-weight: 500;
    margin-top: 16px;

}

.account_main_div .amount h2 span {
    font-size: 1.5rem;
    font-weight: 600;
    font-family: var(--detail_font);
}

.account_box_side_buttons {
    display: flex;
}

.account_box_side_buttons a,
.account_box_side_buttons button {
    border: 1px solid #ff5722;
    padding: 8px 8px;
    font-size: 0.8rem;
    font-weight: 600;
    margin-right: 10px;
    border-radius: 4px;
    color: #000;
    transition: 0.3s all ease-in-out;
    margin-bottom: 10px
}

.account_box_side_buttons a:last-child {
    margin-right: 0;
    background-color: var(--bg_yellow);
    border: 1px solid var(--text_yellow);
}

.account_box_side_buttons a:hover {
    background-color: var(--bg_yellow);
    border: 1px solid var(--text_yellow);
}

.account_box_side_buttons a:last-child:hover {
    margin-right: 0;
    background-color: #ff5722;
    border: 1px solid #ff5722;
    color: #fff;
}


/* ============Withdrawal page================ */
.method_disable {
    cursor: not-allowed;
    opacity: 0.7;
}

.Withdrawal_type_box {
    margin-bottom: 20px;

    border-radius: 4px;
    border: 1px solid #ddd;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    transition: 0.3s all ease-in-out;
}

.Withdrawal_type_box:hover {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.Withdrawal_type_box img {
    max-width: 30px;
}

.Withdrawal_type_name_div {
    display: flex;
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
}

.Withdrawal_type_name_div .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.Withdrawal_type_name_div .inner h3 {
    font-size: 1.1rem;
    margin-bottom: 0;
    margin-left: 10px;
    font-weight: 600;
    color: var(--text_dark);
}

.payment_method_details {
    padding: 8px 16px;

}

.Withdrawal_type_box .payment_method_details ul {
    padding: 0;
    margin: 0;
}

.Withdrawal_type_box .payment_method_details ul li {
    font-size: 0.9rem;
    font-weight: 500;
    color: #333;
}

.Withdrawal_type_box .payment_method_details ul li span:first-child {
    font-weight: 600;
}

.method_disable_lock {
    background-color: var(--bg_yellow);
    color: #fff;
    padding: 5px;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.method_disable_lock .icon {
    font-size: 16px;
}

.Recommended_method h4 {
    margin-bottom: 0;
    font-size: 0.6rem;
    color: #030303;
    font-weight: 500;
    font-family: var(--detail_font);
    background-color: #98d1bd;
    padding: 2px 5px;
    border-radius: 10px;

}

/* =========transaction page============== */
.transaction_filter_div {
    justify-content: flex-start;
}

.transaction_filter_div .account_filter_btn {
    background-color: #e1e1e1;
    font-family: var(--detail_font);
    font-weight: 300;
    border-radius: 20px;
    /* padding: 6px 10px; */
    padding-left: 20px !important;
    margin-right: 10px;
}

.transaction_filter_div .active {
    background-color: var(--bg_yellow);
    color: #fff;
}

/* .transaction_filter_div .account_filter_btn:first-child {
    min-width: 210px;
} */

.transaction_filter_div div {
    margin-right: 10px !important;
}

.common_table table {
    border-radius: 50px;
    margin-top: 30px;
}


.common_table tr td {
    padding: 10px;
    font-size: 0.9rem;
    font-family: var(--commaon_font);
}

/* ==============analytics page============== */
.Market_News_tabs .accordion-header button {
    border-radius: 4px !important;
    border: 1px solid transparent !important;
}

.Market_News_tabs_button_main {
    display: flex;
    align-items: center;
}

.Market_News_thumbnail {
    max-width: 100px;
    margin-right: 10px;
    border-radius: 4px;
}

.news_details h4 {
    font-size: 1rem !important;
    font-weight: 500;
    color: #000 !important;
    font-family: var(--detail_font);
}

.news_details .author_div {
    display: flex;
    align-items: center;
}

.news_details .author_div .author_name {
    font-size: 0.8rem !important;
    font-weight: 600;
    color: #434343 !important;
    font-family: var(--commaon_font);
    margin-right: 10px;
    padding-right: 10px;
    border-right: 1px solid #000;
}

.news_details .author_div .news_date {
    font-size: 0.8rem !important;
    font-weight: 600;
    color: #434343 !important;
    font-family: var(--commaon_font);
    margin-right: 10px;
}

.news_details .news_tegs ul {
    padding: 0;
    display: flex;
}

.news_details .news_tegs ul li {
    padding: 4px 5px;
    background-color: #95eecf;
    font-family: var(--detail_font);
    margin-right: 10px;
    border-radius: 10px;
    font-size: 0.7rem;
    margin-bottom: 0;
    padding-bottom: 3px;
}

.Market_News_tabs .accordion-header button {
    border-radius: 5px !important;
}

.accordion-header button:focus {
    box-shadow: none;
    border: 1px solid transparent !important;
}

/* =======Setting page=============== */

.setting_tabs .Most_Popular_Stocks_tabs .nav-link {
    letter-spacing: 1px;
}

.small_headings h4 {
    font-size: 1.3rem !important;
    font-weight: 600;
    color: #2c2c2c !important;
    font-family: var(--commaon_font);
    margin: 20px 0;
}

.profile_card {
    padding: 10px;
    background-color: #f7f7f7;
}

.profile_img {
    min-width: 50px;
    min-height: 50px;
    width: 50px;
    height: 50px;
    border: 1px dotted #000;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
}

.profile_img img {
    max-width: 32px;
}

.d_flex_both_center {
    display: flex;
    align-items: center;
}

.d_flex_both_center_sp_bt {
    justify-content: space-between;
}

.profile_status h4 {
    font-size: 1rem;
    font-weight: 500;
    color: #434343;
    font-family: var(--commaon_font);
    margin-bottom: 5px;
}

.profile_status h3 {
    font-size: 1.3rem;
    font-weight: 600;
    color: #ef2929;
    font-family: var(--commaon_font);
    margin-bottom: 5px;
}

.profile_status h5 {
    font-size: 0.8rem;
    font-weight: 500;
    color: #434343;
    font-family: var(--commaon_font);
    margin-bottom: 0;
}

.profile_status .profile_amount {
    color: var(--text_dark);
}

.s_small_headings {
    font-size: 0.8rem;
    font-weight: 500;
    color: #434343;
    font-family: var(--commaon_font);
    margin-bottom: 10px;
}

.personal_detail_flex {
    display: flex;
}

.personal_detail_flex h3 {
    font-size: 1rem;
    font-weight: 500;
    color: #131313;
    font-family: var(--commaon_font);
    margin-bottom: 0;
    margin-right: 20px;
}

.login_details {
    padding: 20px;
    margin-top: 30px;
    background-color: whitesmoke;
}

.login_details h3 {
    font-size: 1rem;
    font-weight: 500;
    color: #131313;
    font-family: var(--commaon_font);
    margin-bottom: 0;
    margin-bottom: 10px;
}

.login_details h3 span:first-child {
    display: block;
    font-weight: 600;
    color: #000;
    margin-bottom: 5px;
}

.login_details h3 span:last-child {
    display: block;
    font-weight: 600;
    color: #4f4f4f;
}


/* ===============performance page============= */

.performance_small_box {
    margin-top: 20px;
    padding: 10px 16px;
    border-radius: 4px;
    border: 1px solid #ddd;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.performance_small_box h4 {
    font-size: 1rem;
    font-weight: 500;
    color: #ff5722;
    font-family: var(--commaon_font);
}

.performance_small_box h2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--text_dark);
    font-family: var(--commaon_font);
    margin-bottom: 0;
}


/* ===========Social Trading============= */
.Social_Trading_top_section {
    background-color: #ff5722;
    border-radius: 4px;
    padding: 20px;
    position: relative;
    min-height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.Social_Trading_top_section_content {
    width: 55%;
}

.Social_Trading_top_section h2 {
    color: #fff;
    font-size: 2rem;
    font-weight: 600;
    font-family: var(--detail_font);
}

.Social_Trading_top_section p {
    color: #fff;
}

.Social_Trading_top_section .side_img {
    position: absolute;
    right: 0;
    bottom: 0;
    max-width: 450px;
}

.Start_earning_now_section {
    padding: 80px 0;
}

.Start_earning_now_top_section {
    text-align: center;
}

.Start_earning_now_top_section h1 {
    color: var(--bg_yellow);
    font-size: 2rem;
    font-weight: 600;
    font-family: var(--detail_font);
    text-transform: capitalize;
}

.Start_earning_small_box {
    margin-top: 30px;
}

.Start_earning_small_box h3 {
    text-align: center;
    font-size: 1.3rem;
    font-weight: 500;
    font-family: var(--detail_font);
    margin-top: 20px;
}

.Start_earning_small_box p {
    text-align: center;
    font-size: 0.8rem;
}

.investors_content_main {
    position: relative;
}

.investors_content_main .investors_content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}

.investors_content_main .investors_content h1 {
    color: var(--text_dark);
    font-size: 2rem;
    font-weight: 600;
    font-family: var(--detail_font);
    text-transform: capitalize;
}

.HowWorks_main_section {
    padding: 60px;
    text-align: center;
}

.How_Works_content h1 {
    color: var(--text_dark);
    font-size: 2rem;
    font-weight: 600;
    font-family: var(--detail_font);
    text-transform: capitalize;
}

.How_Works_content p {
    width: 80%;
    margin: auto;
    margin-bottom: 40px;
}

.How_Works_content iframe {
    border-radius: 10px;
}

.top_earning_section table img {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin-right: 8px;
}

.table_green_status {
    background-color: rgb(29, 169, 29) !important;
    padding: 6px 16px;
    border-radius: 20px;
    color: #fff;
    font-weight: 500;
}

.table_red_status {
    background-color: rgb(243, 34, 34) !important;
    padding: 6px 16px;
    border-radius: 20px;
    color: #fff;
    font-weight: 500;
}



/* =============Stock List=========== */

.dashboard_sidebar_main_div .search_box input {
    width: 100%;
}

.pined_stocks {
    padding: 12px 8px;
    border-bottom: 1px solid #949494;
}

.pined_stocks_single {
    display: flex;
}

.pined_stocks_single h4 {
    font-size: 14px;
    color: #282828;
    margin-bottom: 0;
}

.pined_stocks_single h4:first-child {
    border-right: 1px solid #000;
    padding-right: 10px;
    margin-right: 10px;
}

.pined_stocks_single h4 span:nth-child(2) {
    font-size: 10px !important;
    color: #787878;
}

.pined_stocks_single h4 span:last-child {
    font-size: 10px !important;
    color: #787878;

}

.stock_search_div {
    margin-bottom: 20px;
    padding: 0 8px;
}

.stocks_list_single {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 8px;
    border-bottom: 1px solid whitesmoke;
    transition: 0.3 all ease-in-out;
    position: relative;

}

.stocks_list_single h4 {
    font-size: 12px;
    font-weight: 500;
    font-family: var(--commaon_font);
    margin-bottom: 0;
}

.green_text {
    color: #4caf50 !important
}

.red_text {
    color: #e42727 !important
}

.stocks_list_single_buttons {
    display: none;
    transition: 0.3 all ease-in-out;
    position: absolute;
    right: 10px;
    top: 2px;
    background-color: #fff;
}

.stocks_list_single:hover .stocks_list_single_buttons {
    display: block;
}

.watchList_Section .stocks_list_single_buttons {
    display: block;
}

.stocks_list_single_buttons button {
    margin-left: 6px;
    background-color: rgb(255, 255, 255);
    border: 1px solid #b7b7b7;
    outline: none;
    padding: 1px 5px !important;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    min-width: 36px !important;
    font-family: var(--commaon_font);
    color: #282828;

}

.stocks_list_single_buttons .link_btn {
    margin-left: 6px;
    background-color: rgb(255, 255, 255);
    border: 1px solid #b7b7b7;
    outline: none;
    padding: 2px 8px !important;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 400;
    min-width: 36px !important;
    font-family: var(--commaon_font);
    font-size: 18px;
    color: #282828;
}

.stocks_list_single_buttons .buy_btn {
    background-color: #4383f5;
    border: 1px solid #4383f5;
    color: #fff;
}

.stocks_list_single_buttons .sell_btn {
    background-color: #ff5623;
    border: 1px solid #ff5623;

    color: #fff;
}

.stocks_list_single_price_div {
    display: flex;
    align-items: center;
}

.stocks_list_single_price_div h5 {
    font-size: 12px;
    font-weight: 500;
    font-family: var(--commaon_font);
    margin-right: 10px;
    /* color: #787878; */
    margin-bottom: 0;
}

.stocks_list_single_price_div h5:first-child {
    color: #919191;
}

.stocks_list_single_price_div h5:last-child {
    min-width: 60px;
}

.stock_modal_main .modal-header {
    background-color: #0848af;
}

.stock_modal_main .stock_modal_title h4 {
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #fff !important;
}

.stock_modal_main .stock_modal_title h4 span:first-child {
    margin-left: 10px;
    font-size: 8px;
}

.stock_modal_main .stock_modal_title h4 span:last-child {
    font-size: 14px;
    margin-left: 5px;
    margin-top: -2px;
}

.stock_modal_main .stock_modal_title h4 span {
    font-size: 12px;
    color: #e3e3e3;
    margin-bottom: 0;
}

.stock_modal_main .nav-tabs {
    justify-content: flex-start;
}



.stock_modal_main .nav-tabs .nav-link {
    color: #a4a4a4;
    margin: 5px;
    padding: 0;
    margin-right: 20px;
}

.stock_modal_main .nav-tabs .nav-link:focus,
.stock_modal_main .nav-tabs .nav-link:active {
    border: none !important;
    outline: none !important;
}

.stock_modal_main .nav-link,
.nav-tabs .nav-link.active {
    color: #000;
    border: none;
}

.stock_modal_main .nav-tabs .nav-link span {
    font-size: 10px;
    color: #696969;
}

.modal-header .btn-close {
    background-color: #fff;
}

.modal-header .btn-close:focus {
    outline: none;
}

.input-container {
    position: relative;
    margin: 5px;
}

.input-container .input-label {
    position: absolute;
    top: 13px;
    left: 6px;
    transition: 0.3s ease-out;
    pointer-events: none;
    color: #999;
    background-color: #fff;
    font-size: 12px;
}

.input-container input:focus+.input-label,
.input-container input:not(:placeholder-shown)+.input-label,
.input-container:focus-within .input-label {
    top: -10px;
    font-size: 12px;
    color: #333;
    padding: 0 5px;
    border-radius: 2px;
}

.input-container input,
.input-container select {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    min-width: 140px;
    max-width: 160px;
    width: 100%;


}

.validaty_box {
    padding: 10px;

    min-width: 100px;
    max-width: 160px;
    width: 100%;

}

/* Additional styles for better visibility */
.input-container input:focus {
    border-color: #0848af;
    box-shadow: 0 0 5px rgba(8, 72, 175, 0.395);
}

.buy_modal_price_box {
    display: flex;
    justify-content: space-between;
    padding-bottom: 15px;
    margin-top: 10px;
}

.flex_radio_buttons_div {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.flex_radio_buttons_div label {
    font-size: 12px;
    color: #696969 !important;
    font-family: var(--detail_font);
    font-weight: 400;
    margin: 0 3px 0 5px;
}

.show_details_btn {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
}

.show_details_btn button {
    border: none;
    background-color: transparent;
    color: #0848af;
    font-size: 14px;
    font-weight: 500;
    font-family: var(--detail_font);
}

.show_hide_details_div {
    border-top: 1px solid #ddd;
    padding-top: 10px;
    margin-top: 10px;
}

.show_hide_details_div .flex_radio_buttons_div {
    flex-direction: column;
    align-items: flex-start;
}

.stock_modal_main .modal-footer {
    justify-content: space-between;
}

.stocks_modal_footer h4 {
    font-size: 12px;
    font-family: var(--detail_font);
    color: #636363;
    margin-bottom: 0;
    margin-right: 10px;
}

.stocks_modal_footer h4 span {
    color: #030303;
}

.modal-footer .buy {
    margin-right: 10px !important;
}

.modal-footer .buy {
    background-color: #eb3b3b;
}

.modal-footer button:focus {
    outline: none !important;
    box-shadow: none !important;
}

.stock_refresh_btn {
    border: none;
    outline: none;
    background-color: transparent;
    margin: 0 !important;
    padding: 0 !important;
    font-size: 14px;
}

.Stoploss_target_inner {
    display: flex;
    align-items: center;
}

.stoploss_target_div_main {
    padding: 10px;
    border-top: 1px solid #ddd;
}

.Stoploss_target_inner_main {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.Stoploss_target_inner input {
    padding: 4px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    max-width: 50px;
    margin: 0 5px 0px 10px;
}

.Stoploss_target_inner label {
    font-size: 12px;
    color: #696969 !important;
    font-family: var(--detail_font);
    font-weight: 400;
    display: flex;
}

.Stoploss_target_inner input:disabled {
    opacity: 0.5;
}

.stock_sell_modal_main .modal-header {
    background-color: #eb3b3b !important;
}

.stock_sell_modal_main .stock_modal_title {
    background-color: #eb3b3b !important;

}

.leverage_main_div {
    margin-top: 20px;
    margin-bottom: 20px;
}

.leverage_main_div h4 {
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: 30px;
}

.range-slider-container {
    position: relative;
}

.selected-range {
    text-align: center;
}

.value-indicators {
    display: flex;
    justify-content: space-between;
    margin-top: -40px;
    /* Adjust this value as needed to position the indicators */
}

.value-indicators label {
    margin-left: 5px;
}

/* Styling for the slider thumb */
input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    background: #007bff;
    /* Change the color as needed */
    border-radius: 50%;
    cursor: pointer;
}

.value-indicators span {
    font-size: 12px;
}

.Stock_list_sidebar {
    /* max-height: 100vh;
    overflow-y: scroll; */
}

/* width */
.Stock_list_sidebar::-webkit-scrollbar {
    width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.refresh_div {
    text-align: center;
    padding: 10px;
}

.refresh_div p {
    color: #000;
}

.refresh_div button {
    border: 1px solid #d0d0d0;
    padding: 4px 8px;
    font-size: 0.8rem;
    font-weight: 600;
    border-radius: 4px;
    color: #000;
    transition: 0.3s all ease-in-out;
    background-color: #fff;

}

.watchList_Section {
    padding: 12px 8px;
    position: sticky;
    top: 0;
    /* background-color: #f1f1f1; */
    z-index: 9999;
    margin-bottom: 20px;
    box-shadow: none;
}

.stock_list_headings {
    font-size: 18px;
    font-weight: 500;
    font-family: var(--detail_font);
}

.dashboard_sidebar_main_div .nav-tabs .nav-link {
    color: #000;
    background-color: transparent;
    margin-right: 10px;
}

.dashboard_sidebar_main_div .nav-tabs {
    background-color: #e8e8e8;
    padding: 5px;
}

.dashboard_sidebar_main_div .nav-tabs:focus,
.dashboard_sidebar_main_div .nav-tabs:hover {
    outline: none !important;
    border: none !important;
}

.dashboard_sidebar_main_div .nav-tabs .nav-link.active {
    background-color: var(--bg_yellow);
    color: #fff;
}

.dashboard_sidebar_main_div .active {
    box-shadow: none;
}

.Toastify {
    position: relative;
    z-index: 99999999;
}

input:disabled {
    background-color: rgb(231, 229, 229);
    cursor: disabled;
    /* opacity: 0.5; */
}

.input-container .input-label {
    background-color: rgb(231, 229, 229);
    /* opacity: 0.5; */


}

.dashboard_small_headings {
    font-size: 20px;
    margin-bottom: 20px;
}

.dashbaord_table table {
    width: 100%;
    border-radius: 5px;
}

.dashbaord_table {
    margin-top: 20px;

    padding: 20px;
    border-radius: 4px;
    border: 1px solid #ddd;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.dashbaord_table table tr td,
.dashbaord_table table tr th {
    font-size: 13px !important;
}

table tr td,
table tr th {
    font-size: 13px !important;
}

.dashbaord_table .dashboard_link {
    text-align: center;
    margin-top: 20px;
}

.dashbaord_table .dashboard_link a {
    color: var(--text_light);
    background-color: var(--bg_yellow);
    border-color: #dee2e6 #dee2e6 #fff;
    padding: 8px 16px;
    border-radius: 5px;

}

.user_profile_img {
    max-width: 100px;
    border-radius: 50%;
    border: 2px solid #000;
    margin-bottom: 30px;
}

.dashboard_boxes {
    margin-bottom: 20px;
    padding: 10px 5px;
    border-radius: 4px;
    border: 1px solid #eee;
    /* background-color: #fff; */
    min-height: 138px;
    /* box-shadow: rgba(113, 113, 113, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px; */

}

.dashboard_boxes .heading {
    font-size: 15px;
    margin-bottom: 20px;
    color: #444;
    font-weight: 400;
}

.dashboard_boxes .heading .icon {
    margin-right: 10px;
    font-size: 18px;
}

.dashboard_boxes_flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dashboard_boxes .dashboard_boxes_left_side:nth-child(2) {
    border-left: 1px solid #eee;
    padding-left: 20px;
}

.dashboard_boxes .dashboard_boxes_left_side h3 {
    font-size: 1.8rem;
    font-family: sans-serif;
    color: #444;
    margin-bottom: 5px;
}

.dashboard_boxes .dashboard_boxes_left_side h5 {
    font-size: 10px;
    font-family: var(--detail_font);
    color: #9b9b9b;
    font-weight: 300;
    text-transform: capitalize;
}

.dashboard_boxes .dashboard_boxes_left_side h5 span {
    color: #5b5b5b;
    margin-left: 6px;
    font-size: 12px;
}

.StockdioWidget_WatermarkBottom {
    display: none !important;
    opacity: 0;
}

.jchartfx .AdditionalUI {
    display: none;
}

.static_stock_box {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.nav-tabs .nav-link.active {
    background-color: var(--bg_yellow);
    color: #fff;
}

.table_buttons {
    padding: 5px 8px;
    border-radius: 5px;
    border: none;
}

.table_buttons_danger {

    background-color: rgb(254, 47, 47);
    color: #fff;
}

.table_buttons_edit {
    background-color: rgb(47, 14, 234);
    color: #fff;
    margin-left: 10px;
}

.stock_modal_edit .modal-footer {
    justify-content: end;
}

.Tabs_balance_div {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Tabs_balance_div h4 {
    font-size: 16px;
    margin-bottom: 5px;
}

.stocks_list_main_outer {
    position: relative;
    height: 100vh;
    overflow-y: scroll;
}

.pagination {
    position: sticky;
    bottom: 0;
    z-index: 99;
    background-color: var(--bg_yellow);
    width: 100%;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #fff;
}

.pagination button {
    background-color: 000;
    border: 1px solid #fff;
    box-shadow: none;
    outline: none;
    padding: 5px;
    border-radius: 4px;
}

.interval_btns button {
    margin-left: 8px;
}

.interval_btns .active {
    background-color: var(--bg_yellow) !important;
    color: #fff !important;
}

/* tv-lightweight-charts */
.dashboard_boxes_main_section>div>div.tv-lightweight-charts {
    display: none;
}

.dashboard_boxes_main_section>div>div.tv-lightweight-charts:nth-child(1) {
    display: block;
}

.error_modal_box img {
    max-width: 350px;
}

.error_modal_box .modal-body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.error_modal_box .modal-body h3 {
    font-size: 18px;
    font-family: var(--detail_font);
    color: rgb(26, 26, 26);
    font-weight: 400;
    text-transform: capitalize;
    text-align: center;
    margin-top: 30px;
}